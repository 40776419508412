import "../styles/MyDashboard.css";

import { Image, Spinner } from "@fluentui/react-components";
import { BaseDashboard } from "@microsoft/teamsfx-react";

import { Team } from "../widgets/Team";

export default class MyDashboard extends BaseDashboard<any, any> {
  override layout(): JSX.Element | undefined {
    return (
      <>
        {this.state.showLogin === false ? (
          <>
            <Image className="img-style" src="bg.jpg" />
            <Team />
          </>
        ) : (
          <div className="spinner-layout">
            <Spinner size="huge" />
          </div>
        )}
      </>
    );
  }

  override styling(): string {
    return this.state.isMobile === true ? "dashboard-mobile" : "dashboard";
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ showLogin: false });
  }
}
