import { TeamModel } from "../models/teamModel";
import { callFunction } from "./callFunction";

/**
 * Retrieves tasks from the server.
 * @returns An array of TeamModel objects.
 */
export async function getTeams(all: boolean): Promise<TeamModel[]> {
  try {

    let params: { [key: string]: string } = {};
    params.graphType = "team";
    if (all) {
      params["all"] = "true";
    }
    const respData = await callFunction("GET", "callTeamsMgmtApi", params);
    return respData["teamResult"];
  } catch (e) {
    throw e;
  }
}

/**
 * Joins a team
 * @returns A team deep-link if successful.
 */
export async function joinTeam(teamId : string): Promise<any> {
  try {
    // Post to GraphAPI to join a team
    const respData = await callFunction("POST", "callGraph", { graphType: "team" }, { teamId: teamId });
    return respData;
  } catch (e) {
    throw e;
  }
}

/**
 * Lists joined teams
 * @returns A list of team ids.
 */
export async function getJoinedTeams(): Promise<TeamModel[]> {
  try {
    // Post to GraphAPI to join a team
    const respData = await callFunction("GET", "callGraph", { graphType: "team" });
    return respData["teamResult"];
  } catch (e) {
    throw e;
  }
}

export async function getIcon(teamId : string) : Promise<any> {
  try {
    const respData = await callFunction("GET", "callGraph", { graphType: "icon", teamId: teamId});

    return respData.iconResult;
  } catch (e) {
    throw e;
  }
}
