import "../styles/Common.css";
import "../styles/Task.css";

import React from "react";

import { mergeStyles } from "@fluentui/react";
import { Button, Image, Spinner, Text } from "@fluentui/react-components";
import {
  Search20Filled,
  Search20Regular,
} from "@fluentui/react-icons";
import { BaseWidget, IWidgetClassNames } from "@microsoft/teamsfx-react";

import { EmptyThemeImg } from "../components/EmptyThemeImg";
import { TeamsFxContext } from "../internal/context";
import { TeamModel } from "../models/teamModel";
import { getTeams, joinTeam, getJoinedTeams, getIcon } from "../services/teamService";

import { app } from "@microsoft/teams-js";

interface ITeamState {
  teams?: TeamModel[];
  filteredTeams?: TeamModel[];
  joinedTeams?: TeamModel[];
  filter?: string;
  showAll?: boolean;
  inputFocused?: boolean;
  addBtnOver?: boolean;
  addBtnClicked?: boolean;
  partiallyLoaded?: boolean;
}

export class Team extends BaseWidget<any, ITeamState> {
  inputDivRef;
  btnRef;
  inputRef;

  constructor(props: any) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
    this.inputDivRef = React.createRef<HTMLDivElement>();
    this.btnRef = React.createRef<HTMLButtonElement>();
  }

  override async getData(): Promise<ITeamState> {
    return await this.loadData().then((data) => { 
      data.teams.forEach(async (team) => {
        // we don't use the teams array for state directly so we'll just mutate this item
        team.icon = await getIcon(team.officeGroupId);

        // we do use the filteredTeams array for state so we'll update that with a map
        this.setState({filteredTeams: this.state.filteredTeams?.map((t) => {
          if (t.officeGroupId === team.officeGroupId) {
            t.icon = team.icon;
          }
          return t;
        })});
      });

      return data;
    });
  }

  override componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ITeamState>, snapshot?: any): void {
    if (!this.state.teams) 
      return;

    if (this.state.filter !== prevState.filter) {
      const filteredTeams = this.state.teams?.filter(
        (team) => this.teamMatchesFilter(team, this.state.filter));
      this.setState({ filteredTeams });
    }
  }

  private teamMatchesFilter(team: TeamModel, filter: string): boolean {
    if (!filter) {
      return true;
    }
    const lowerFilter = filter.toLowerCase();
    if (team.name && team.name.toLowerCase().includes(lowerFilter)) {
      return true;
    }
    if (team.description && team.description?.toLowerCase().includes(lowerFilter)) {
      return true;
    }
    return false;
  }

  async loadData(): Promise<ITeamState> {
    try {
      const teamsPromise = getTeams(true).then((z) => {this.setState( {partiallyLoaded : true}); return z;});
      const joinedTeamsPromise = getJoinedTeams().then((z) => {this.setState( {partiallyLoaded : true}); return z;});
      let [teams, joinedTeams] = await Promise.all([teamsPromise, joinedTeamsPromise]); 
      return { teams, joinedTeams, filteredTeams: teams, inputFocused: false, addBtnOver: false };
    } catch (error) {
      console.error(error);
      return { teams: [], joinedTeams: [], filteredTeams: [], inputFocused: false, addBtnOver: false };
    } finally {
      this.setState({ loading: false });
    }
  }

  override header(): JSX.Element | undefined {
    return (
      <div>
        <TeamsFxContext.Consumer>
          {({ themeString }) =>
            themeString === "default" ? <Image src={`teams.svg`} /> : <Image src={`teams.svg`} />
          }
        </TeamsFxContext.Consumer>
      </div>
    );
  }

  override body(): JSX.Element | undefined {
    const hasTeams = this.state.teams?.length !== 0;
    return (
      <div className={hasTeams ? "has-task-layout" : ""}>
        {this.inputLayout()}
        {hasTeams ? (
          this.state.filteredTeams?.map((item: TeamModel) => {
            const isJoined = this.state.joinedTeams?.findIndex((team) => team.id === item.officeGroupId) !== -1;
            const hasIcon = item.icon !== undefined;
            return (
              <div key={`div-task-item-${item.id}`} className="task-item">
                { hasIcon ? <img className="icon" src={item.icon} alt="icon" /> : <div className="icon-placeholder"></div> }
                {item.name}
                
                { isJoined ? 
                  <Button name="Go" appearance="primary" onClick={async (event) => {
                    try {
                      await app.openLink(item.url ?? "");
                    } catch (error) {
                      console.error(error);
                    }
                  }} >Go</Button> : 
                  <Button name="Join" onClick={async (event) => {
                    let target = event.currentTarget as HTMLButtonElement;
                    target.disabled = true;
                    target.textContent = "Joining…";
                    let result = await joinTeam(item.officeGroupId);
                    if (result && result.teamLink) {
                      try {
                        await app.openLink(result.teamLink ?? "");
                        target.textContent = "Go";
                      } catch (error) {
                        console.error(error);
                      }
                    }
                    else
                    {
                      target.textContent = "Join";
                      console.error("Failed to join team");
                    }
                    target.disabled = false;
                  }}>Join</Button> 
                  }
              </div>
            );
          })
        ) : (
          <div className="empty-layout">
            <EmptyThemeImg />
            <Text>No teams are available, when they are you'll find them here</Text>
          </div>
        )}
      </div>
    );
  }



  override footer(): JSX.Element | undefined {
    return undefined;
  }

    // This function returns the JSX for the input layout
    private inputLayout(): JSX.Element | undefined {
      return (
        <div
          ref={this.inputDivRef}
          className={mergeStyles(
            "add-task",
            this.state.inputFocused ? "focused-color" : "non-focused-color"
          )}
        >
          {this.state.inputFocused ? (
            <Search20Filled className="add-btn" />
          ) : (
            <Search20Regular className="add-btn" />
          )}
  
          <input autoFocus
            ref={this.inputRef}
            type="text"
            className={mergeStyles(
              "input",
              this.state.inputFocused ? "focused-color" : "non-focused-color"
            )}
            onFocus={() => this.setState({ inputFocused: true })}
            onBlur={() => this.setState({ inputFocused: this.state.filter !== "" })}
            onKeyUp={() => this.setState({ filter: this.inputRef.current?.value })}
            placeholder="Search for team"
          />
        </div>
      );
    }
  
  override styling(): IWidgetClassNames {
    return { root: "concise-root", footer: "footer-btn" };
  }

  override loading(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label={this.state.partiallyLoaded ? "Almost done…" : "Loading…"} labelPosition="below" />
      </div>
    );
  }



}
